// Step 1: Import React
import * as React from 'react'
import { Link } from 'gatsby'
import Layout2 from '../components/layout2'
import { StaticImage } from 'gatsby-plugin-image'
import Seo2 from '../components/seo2'

// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout2 pageTitle="Welcome!">
      <p>Hey! Thanks for visiting The Wandering Boy! In my quest to learn and try something new, I rediscovered a teenage passion for web development and blogging. Thank goodness those emo days on Xanga are over... Now I have the time and energy to blog about positive things! Feel free to stick around and hopefully we will be able to teach each other something new. </p>
    <StaticImage
        alt="A picture of Jeff in front of ruins at Chichen Itza"
        src="../images/IMG_1306.png"
      />    
      <br></br>
      <br></br>
      </Layout2>
  )
}

// You'll learn about this in the next task, just copy it for now
export const Head = () => <Seo2 title="Home Page" />

// Step 3: Export your component
export default IndexPage